import styled from "@emotion/styled"
import {Paper, Typography} from "@material-ui/core"
import Timeline from "@material-ui/lab/Timeline"
import TimelineConnector from "@material-ui/lab/TimelineConnector"
import TimelineContent from "@material-ui/lab/TimelineContent"
import TimelineDot from "@material-ui/lab/TimelineDot"
import TimelineItem from "@material-ui/lab/TimelineItem"
import TimelineSeparator from "@material-ui/lab/TimelineSeparator"
import {FaQuestionCircle} from "@react-icons/all-files/fa/FaQuestionCircle"
import {GiCogsplosion} from "@react-icons/all-files/gi/GiCogsplosion"
import {GiNewspaper} from "@react-icons/all-files/gi/GiNewspaper"
import {GiWorld} from "@react-icons/all-files/gi/GiWorld"
import {IoConstructOutline} from "@react-icons/all-files/io5/IoConstructOutline"
import * as React from "react"
import {Layout} from "../components/Layout"
import {SectionContainer} from "../components/Shared"

const TimeLinePaper = styled(Paper)(({theme}) => ({
  padding: theme.spacings.md
}))

const todos = [
  {
    icon: <GiWorld size={25}/>,
    title: "I18n",
    content: "Add internationalization in the project with markdown support",
    done: true
  },
  {
    icon: <FaQuestionCircle size={25}/>,
    title: "F.A.Q",
    content: "Add a real FAQ",
    done: true
  },
  {
    icon: <IoConstructOutline size={25}/>,
    title: "Diy",
    content: "Do it yourself page in 4 steps"
  },
  {
    icon: <GiNewspaper size={25}/>,
    title: "News",
    content: "The blog page will replace the blog, with a blog feed and a twitter feed"
  },
  {
    icon: <GiCogsplosion size={25}/>,
    title: "Systemes",
    content: "Supported systems"
  },
  {
    icon: <GiCogsplosion size={25}/>,
    title: "Social Network",
    content: "Add social network links"
  },
  {
    icon: <IoConstructOutline size={25}/>,
    title: "Tutorials",
    content: "Select and add all tutorials for the tutorial section"
  },
  {
    icon: <GiWorld size={25}/>,
    title: "I18n",
    content: "Translate the application"
  }
]

const TodoPage = () => {
  return (
    <Layout title={"Recalbox Website MVP Todo List"}>
      <SectionContainer>
        <Timeline align="alternate">
          {todos.map((todo) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={todo.done ? "secondary" : "inherit"}>{todo.icon}</TimelineDot>
                <TimelineConnector/>
              </TimelineSeparator>
              <TimelineContent>
                <TimeLinePaper elevation={3}>
                  <Typography variant="h6" component="h1">
                    {todo.title}
                  </Typography>
                  <Typography>{todo.content}</Typography>
                </TimeLinePaper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </SectionContainer>
    </Layout>
  )
}

export default TodoPage
